<template>
  <div class="menu">
    <div class="screening">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item title="统计栏" name="1">
          <div class="out-box">
            <el-row :gutter="30">
              <el-col :span="6">
                <div class="statistical-son-box bg-color1">
                  <div class="count-box">
                    <div>
                      <div class="font-color title1">
                        <icon-font
                          type="icon-cishu_data-sheet-01"
                          class="font-size4"
                        />
                        接通率
                      </div>
                      <div class="font-color font-size1">
                        <!-- <span class="font-size9">￥</span> -->
                        {{
                          countList?.percentage == null
                            ? "0"
                            : countList?.percentage
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="statistical-son-box bg-color3">
                  <div class="count-box">
                    <div>
                      <div class="font-color title1">
                        <icon-font
                          type="icon-cishu_data-sheet-03"
                          class="font-size4"
                        />
                        通话分钟数
                      </div>
                      <div class="font-color font-size1">
                        {{
                          countList?.callTime == null
                            ? "0"
                            : countList?.callTime
                        }}
                        <span class="font-size9">min</span>
                      </div>
                      <div v-if="userType == 'system'">
                        <span class="font-color">原始通话时间</span>
                        <span class="font-color font-size3">
                          {{
                            countList?.originalMinutes == null
                              ? "0"
                              : countList?.originalMinutes
                          }}
                          <span class="font-size9">次</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="statistical-son-box bg-color4">
                  <div class="count-box">
                    <div>
                      <div class="font-color title1">
                        <icon-font
                          type="icon-cishu_data-sheet-04"
                          class="font-size4"
                        />
                        通话费
                      </div>
                      <div class="font-color font-size1">
                        <span class="font-size9">￥</span>
                        {{
                          countList?.callCharge == null
                            ? "0"
                            : countList?.callCharge
                        }}
                      </div>
                      <div v-if="userType == 'system'">
                        <span class="font-color">成本通话费</span>
                        <span class="font-color font-size2">
                          <span class="font-size9">￥</span>
                          {{
                            countList?.costPrice == null
                              ? "0"
                              : countList?.costPrice
                          }}
                        </span>
                      </div>
                      <div v-if="userType == 'system'">
                        <span class="font-color">原始通话费</span>
                        <span class="font-color font-size3">
                          <span class="font-size9">￥</span>
                          {{
                            countList?.originalPrice == null
                              ? "0"
                              : countList?.originalPrice
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="statistical-son-box bg-color2">
                  <div class="count-box">
                    <div>
                      <div class="font-color title1">
                        <icon-font
                          type="icon-cishu_data-sheet-05"
                          class="font-size6"
                        />
                        接通数
                      </div>
                      <div class="font-color font-size5">
                        {{
                          countList?.callNum == null ? "0" : countList?.callNum
                        }}
                        <span class="font-size9">次</span>
                      </div>
                      <div>
                        <span class="font-color">话单数</span>
                        <span class="font-color font-size2">
                          {{
                            countList?.billNum == null
                              ? "0"
                              : countList?.billNum
                          }}
                          <span class="font-size9">次</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-collapse-item>
        <el-collapse-item title="筛选栏" name="2">
          <div class="screening-out">
            <el-form :inline="true" class="demo-form-inline">
              <el-row>
                <el-col :span="6">
                  <el-form-item label="昵称">
                    <el-input
                      v-model="searchFrom.nickname"
                      placeholder="请输入需要查询的昵称"
                      size="mini"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="手机号">
                    <el-input
                      v-model="searchFrom.number"
                      placeholder="请输入需要查询的手机号"
                      size="mini"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="所属接口：" prop="interfaceId">
                    <el-select
                      v-model="interfaceValue"
                      placeholder="请选择"
                      size="mini"
                    >
                      <el-option
                        v-for="item in interface_id"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <div class="title-buttom">
                    <el-button class="cancel" size="small" @click="reset" round
                      >重置</el-button
                    >
                    <el-button
                      type="primary"
                      size="small"
                      class="confirmAdd"
                      @click="onSubmit"
                      round
                      >查询</el-button
                    >
                  </div>
                </el-col>
              </el-row>
              <!-- <el-row>
                                <el-col :span="12">
                                    <el-form-item label="查询时间段">
                                        <el-date-picker
                                            v-model="valueTime"
                                            type="datetimerange"
                                            value-format=“YYYY-MM-DD”
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            :default-time="defaultTime"
                                            size="mini"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    
                                </el-col>
                                <el-col :span="6">
                                  
                                </el-col>
                            </el-row> -->
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <el-row>
      <el-col :span="12">
        <div class="title-name">数据列表</div>
      </el-col>
      <el-col :span="12">
        <div class="title-buttom">
          <el-tooltip content="刷新列表" placement="top" effect="light">
            <el-button
              class="editor"
              size="mini"
              icon="el-icon-refresh-right"
              @click="getDataList(true)"
              circle
            ></el-button>
          </el-tooltip>
          <!-- <el-button class="deletetable" size="mini" icon="el-icon-delete" @click="deleteData" round>删除</el-button> -->
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div>
          <el-table
            class="eltable"
            v-loading="loading"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
            row-key="id"
            :max-height="tableHeight"
            default-expand-all
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @selection-change="handleSelectionChange"
          >
            <!-- <el-table-column
                        type="selection"
                        width="55">
                        </el-table-column> -->
            <el-table-column v-if="userType == 'system'" label="昵称">
              <template #default="scope">{{
                scope.row.nickname == null || scope.row.nickname == ""
                  ? "-"
                  : scope.row.nickname
              }}</template>
            </el-table-column>
            <el-table-column label="号码">
              <template #default="scope">{{
                scope.row.number == null || scope.row.number == ""
                  ? "-"
                  : scope.row.number
              }}</template>
            </el-table-column>
            <el-table-column label="绑定数">
              <template #default="scope">{{
                scope.row.bindNum == null || scope.row.bindNum == ""
                  ? "-"
                  : scope.row.bindNum
              }}</template>
            </el-table-column>
            <el-table-column label="话单数">
              <template #default="scope">{{
                scope.row.billNum == null || scope.row.billNum == ""
                  ? "-"
                  : scope.row.billNum
              }}</template>
            </el-table-column>
            <el-table-column label="接通数">
              <template #default="scope"
                >{{
                  scope.row.callNum == null || scope.row.callNum == ""
                    ? "-"
                    : scope.row.callNum
                }}次</template
              >
            </el-table-column>
            <el-table-column label="通话分钟数">
              <template #default="scope"
                >{{
                  scope.row.callTime == null || scope.row.callTime == ""
                    ? "-"
                    : scope.row.callTime
                }}分钟</template
              >
            </el-table-column>
            <el-table-column v-if="userType == 'system'" label="原始通话分钟数">
              <template #default="scope"
                >{{
                  scope.row.originalMinutes == null ||
                  scope.row.originalMinutes == ""
                    ? "-"
                    : scope.row.originalMinutes
                }}分钟</template
              >
            </el-table-column>
            <el-table-column label="通话费">
              <template #default="scope"
                >￥{{
                  scope.row.callCharge == null || scope.row.callCharge == ""
                    ? "-"
                    : scope.row.callCharge
                }}</template
              >
            </el-table-column>
            <el-table-column v-if="userType == 'system'" label="原始通话费">
              <template #default="scope"
                >￥{{
                  scope.row.originalPrice == null ||
                  scope.row.originalPrice == ""
                    ? "-"
                    : scope.row.originalPrice
                }}</template
              >
            </el-table-column>
            <el-table-column v-if="userType == 'system'" label="成本通话费">
              <template #default="scope"
                >￥{{
                  scope.row.costPrice == null || scope.row.costPrice == ""
                    ? "-"
                    : scope.row.costPrice
                }}</template
              >
            </el-table-column>
            <el-table-column label="所属接口">
              <template #default="scope">{{
                scope.row.interfaceId == null || scope.row.interfaceId == ""
                  ? "-"
                  : mateInterface(scope.row.interfaceId)
              }}</template>
            </el-table-column>
            <el-table-column label="日期">
              <template #default="scope">{{
                scope.row.date == null || scope.row.date == ""
                  ? "-"
                  : scope.row.date
              }}</template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <div class="pager">
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          :page-sizes="[10, 20, 30, 40]"
          :page-size="searchFrom.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import userNumberCount from "@/api/open/userNumberCount/userNumberCount";
import interfacets from "@/api/open/privacy/interface";
import { IconFont } from "@/utils/iconfont";
export default {
  components: {
    IconFont,
  },
  data() {
    return {
      tableHeight: "",
      searchFrom: {
        page: 1,
        pageSize: 10,
        nickname: "",
        number: "",
        interfaceId: "",
        dateBegin: "",
        dateEnd: "",
      },
      total: 0,
      currentPage: 1, //分页
      multipleSelection: [], //选中集合
      valueTime: "", //选中时间段
      defaultTime: [
        new Date(2000, 1, 1, 12, 0, 0),
        new Date(2000, 2, 1, 8, 0, 0),
      ], //默认时间 '12:00:00', '08:00:00'
      activeNames: [], //折叠面板
      tableData: [], //菜单表
      // loding 状态启用
      loading: true,
      totalList: {},
      countList: {},
      userType: "web",
      interface_id: [],
      interface_list: {},
      interfaceValue: "",
    };
  },
  created() {
    this.tableHeight = this.$store.state.tableHeight;
    this.userType = this.$store.getters.userType;
  },
  watch: {},
  mounted() {
    this.getApiType();
    this.getDataList(true);
  },
  methods: {
    mateInterface(item) {
      return this.interface_list[item];
    },
    getApiType() {
      interfacets.seeApiList({}).then((res) => {
        this.interface_id = res.data;
        let newJson = {};
        res.data.forEach((item) => {
          newJson[item.id] = item.name;
        });
        this.interface_list = newJson;
      });
    },
    // 查询
    onSubmit() {
      this.searchFrom.interfaceId = this.interfaceValue;
      this.searchFrom.dateBegin = this.valueTime[0];
      this.searchFrom.dateEnd = this.valueTime[1];
      this.getDataList(true);
    },
    // 重置
    reset() {
      (this.searchFrom = {
        page: this.currentPage,
        pageSize: 10,
        nickname: "",
        number: "",
        interfaceId: "",
        dateBegin: "",
        dateEnd: "",
      }),
        (this.interfaceValue = "");
      this.valueTime = [];
      this.getDataList(true);
    },
    // 获取数据列表
    getDataList(type) {
      this.loading = type;
      userNumberCount
        .dailyBillCount({
          ...this.searchFrom,
        })
        .then((res) => {
          this.countList = res.data;
        });
      userNumberCount
        .dailyBillList({
          ...this.searchFrom,
        })
        .then((res) => {
          this.tableData = res.data.records;
          this.total = res.data.total;
          this.loading = false;
        });
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.searchFrom.pageSize = val;
      this.getDataList(true);
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.searchFrom.page = val;
      this.currentPage = val;
      this.getDataList(true);
    },
    // 折叠面板
    handleChange(val) {
      console.log(val);
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量删除
    deleteData() {
      console.log(this.multipleSelection);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  height: 100%;
  overflow-y: overlay;
  padding-right: 1.125rem;
  .title-name {
    font-size: 0.9375rem;
    font-weight: 600;
    color: #303133;
  }
  .top-first {
    .search-box {
      width: 18.75rem;
    }
  }
}
.screening {
  margin-bottom: 0.9375rem;
}
.el-row {
  margin-bottom: 0.9375rem;
  &:last-child {
    margin-bottom: 0;
  }
}
.out-box {
  padding: 0.8rem;
}
.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.editor {
  color: #0170ed;
  background: #e3f0ff;
}
.center-body {
  margin: 0 1.875rem;
}
.deletetable {
  color: #ff845b;
  background: #ffe4db;
}
.deletetable-other {
  margin-right: 1.25rem;
}
.confirmAdd {
  background: #637dff;
  color: #fff;
}
.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}
.screening-out {
  margin: 0.9375rem;
}
.out-icon:hover {
  background: #e3f0ff;
  color: #0170ed;
}
.pager {
  display: flex;
  justify-content: flex-end;
}
.statistical-box {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem;
}
.statistical-son-box {
  border-radius: 0.666rem;
  padding: 0.8rem 0;
  box-shadow: 0px 0px 6px 0px #d3d6e2;
  // padding: 1rem 3.5rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.title1 {
  font-size: 1.4rem;
  display: flex;
  align-items: center;
}
.font-size1 {
  text-indent: 0.5rem;
  color: #637dff;
  font-weight: 700;
  font-size: 2rem;
}
.font-size2 {
  display: inline-block;
  text-align: right;
  // width: 5rem;
  font-size: 1rem;
  margin-left: 0.666rem;
}
.font-size3 {
  display: inline-block;
  text-align: right;
  // width: 5rem;
  font-size: 1.375rem;
  margin-left: 0.666rem;
}
.font-size4 {
  color: #637dff;
  margin-right: 0.5rem;
  font-size: 2rem;
}
.font-size5 {
  text-indent: 0.5rem;
  color: #67c23a;
  font-weight: 700;
  font-size: 2rem;
}
.font-size6 {
  color: #67c23a;
  margin-right: 0.5rem;
  font-size: 2rem;
}
.font-size7 {
  color: #fca603;
  margin-right: 0.5rem;
  font-size: 2rem;
}
.font-size8 {
  text-indent: 0.5rem;
  color: #fca603;
  font-weight: 700;
  font-size: 2rem;
}
.font-size9 {
  font-size: 1rem;
}
.img-icon {
  font-size: 0.75rem;
  color: #637dff;
}
.count-box {
  height: 10.625rem;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
// .bg-color1{
//     background: linear-gradient(90deg, #8de0e9, #37d6e7);;
// }
// .bg-color2{
//     background: linear-gradient(90deg, #e98888, #e53d3d);;
// }
// .bg-color3{
//     background: linear-gradient(90deg, #bc4beb, #814beb);;
// }
// .bg-color4{
//     background: linear-gradient(90deg, #4d4beb, #4b7ceb);;
// }
// .bg-color5{
//     background: linear-gradient(90deg, #97eb4b, #e9eb4b);;
// }
</style>

<style lang="scss">
.menu .el-collapse-item__content {
  padding-bottom: 0 !important;
}
.eltable {
  overflow-y: overlay;
}
</style>